import http from '@/utils/http';

/**
 * 上课
 */
export default {
  getList: {
    p: 'post,/manage/reservation/getlist',
    r: (data) =>
      http({ url: '/manage/reservation/getlist', method: 'post', data })
  },
  add: {
    p: 'post,/manage/reservation/add',
    r: (data) => http({ url: '/manage/reservation/add', method: 'post', data })
  },
  update: {
    p: 'post,/manage/reservation/update',
    r: (data) =>
      http({ url: '/manage/reservation/update', method: 'post', data })
  },
  del: {
    p: 'post,/manage/reservation/delete',
    r: (data) =>
      http({ url: '/manage/reservation/delete', method: 'post', data })
  },
  getOne: {
    p: 'post,/manage/reservation/getOne',
    r: (data) =>
      http({ url: '/manage/reservation/getOne', method: 'post', data })
  },
  queryHave: {
    p: 'post,/manage/reservation/query_class',
    r: (data) =>
      http({ url: '/manage/reservation/query_class', method: 'post', data })
  },
  haveClass: {
    p: 'post,/manage/reservation/have_class',
    r: (data) =>
      http({ url: '/manage/reservation/have_class', method: 'post', data })
  },
  getExport: {
    p: 'post,/manage/reservation/export',
    r: (data) =>
      http({ url: '/manage/reservation/export', method: 'post', data })
  }
};
