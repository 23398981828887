<template>
  <div :class="{ 'page-class': !$store.getters.tableConfig.card }">
    <avue-crud
      :data="tableData"
      :option="tbleOption"
      @row-save="handleSubmit"
      @row-update="rowUpdate"
      @refresh-change="getList"
      @row-del="rowDel"
      @on-load="onLoad"
      @search-change="searchChange"
      @search-reset="searchReset"
      :page.sync="page"
    >
    <template
        slot="searchMenu"
        slot-scope="{ row, size }"
      >
        <el-button
          icon="el-icon-download"
          :size="size"
          @click="searchExcel(row,size)"
        >导出</el-button>
</template>
      <template slot="member_nameSearch">
        <div style="height: 32px;width: 100%">
          <avue-form ref="form" :option="memberSearchOp" v-model="memberSearchFo" ></avue-form>
        </div>
      </template>
      <template slot-scope="{ row, disabled, size }" slot="referrerSearch">
        <avue-input-table
          :size="size"
          :props="referrerInputTabel.props"
          :column="referrerInputTabel.column"
          :formatter="referrerFormatter"
          :on-load="referrerOnLoad"
          v-model="searchData.referrer"
          placeholder="请选择推荐人"
        ></avue-input-table>
      </template>
      <template slot-scope="{ type, size, row, index }" slot="menu">
        <el-button
        v-if="row.status ===3"
            icon="el-icon-s-grid"
            :size="size"
            :type="type"
            @click.stop="growthClick(row, index)"
        >成长记录</el-button
        >
        <!-- <el-button
            icon="el-icon-edit-outline"
            :size="size"
            :type="type"
            @click.stop="redact(row, index)"
        >编辑</el-button>
        <el-button
            icon="el-icon-delete"
            :size="size"
            :type="type"
            @click.stop="rowDel(row, index)"
        >删除</el-button
        > -->
      </template>
    </avue-crud>
    <!--成长记录-->
    <el-drawer
        title="成长记录"
        size="80%"
        :close-on-click-modal="false"
        :visible.sync="growthVisible"
    >
      <avue-form
          ref="planForm"
          v-model="formGrowthDa"
          :option="formGrowthOp"
          @submit="growthRowUpdate"
          @reset-change="growthEmptytChange"
      >
        <!-- 能量值充值-能量值属性 -->
        <template slot="type">
          <el-select  placeholder="请选择所属分类" v-model="formGrowthDa.type">
            <el-option  :value="1" label="图文"></el-option>
            <el-option  :value="2" label="视频"></el-option>
          </el-select>
        </template>
<!--        <template slot="review">-->
<!--          <el-select  placeholder="请选择所属分类" v-model="formGrowthDa.type">-->
<!--            <el-option  :value="1" label="图文"></el-option>-->
<!--            <el-option  :value="2" label="视频"></el-option>-->
<!--          </el-select>-->
<!--        </template>-->
      </avue-form>
    </el-drawer>

    <!--活动计划-->
    <el-drawer
        title="编辑"
        size="900"
        :close-on-click-modal="false"
        :visible.sync="planDialogVisible"
    >
      <avue-form
          ref="planForm"
          v-model="formPlanDa"
          :option="formPlanOp"
          @submit="rowUpdate"
          @reset-change="emptytChange"
      >
      </avue-form>
    </el-drawer>
  </div>
</template>
<script>
import List from './have_class';

export default List;
</script>
