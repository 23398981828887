import './list.scss';
import dataApi from '@/api/manage/haveClass.js';
import growApi from '@/api/manage/grow.js';
import member from '@/api/member/member';
/*
 * 活动签到
 *  */
export default {
  name: 'class-list',
  components: {},
  data() {
    return {
      memberSearchOp: {
        span: 24,
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            labelWidth: 0,
            label: '',
            prop: 'table',
            type: 'table',
            children: {
              border: true,
              searchMenuSpan: 8,
              column: [
                {
                  type: 'input',
                  label: '会员名称',
                  display: true,
                  prop: 'name',
                  searchLabelWidth: 100,
                  search: true
                },
                {
                  type: 'input',
                  label: '手机号',
                  display: true,
                  prop: 'mobile',
                  searchLabelWidth: 100,
                  search: true
                },
                {
                  label: '能量值',
                  prop: 'energy'
                },
                {
                  label: '赠送能量值',
                  prop: 'giving_energy'
                }
              ]
            },
            formatter: (row) => {
              if (!row.name) return '';
              return `${row.name}`;
            },
            onLoad: ({ page, value, data }, callback) => {
              // 首次加载去查询对应的值
              if (value) {
                return;
              }
              if (page) {
                console.log(page);
              }
              // 请求列表数据
              member.getList.r(data).then((res) => {
                // 渲染数据
                callback({
                  total: res.data.total,
                  data: [...res.data.list]
                });
              });
            },
            props: {
              label: 'name',
              value: 'id'
            }
          }
        ]
      },
      memberSearchFo: {},
      growthVisible: false,
      planDialogVisible: false,
      formPlanDa: {},
      page: {
        pageSize: 20,
        pageSizes: [20, 30, 40, 50, 100]
      },
      tableData: [],
      tbleOption: {
        total: 20,
        align: 'center',
        cellBtn: false,
        cancelBtn: false,
        ...this.$store.getters.tableConfig,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        selection: false,
        filterBtn: false,
        column: [
          {
            label: '会员名称',
            prop: 'member_name',
            type: 'input',
            search: true
          },
          {
            label: '签到活动',
            prop: 'course_name',
            type: 'select'
          },
          {
            label: '签到活动',
            prop: 'product_id',
            type: 'select',
            search: true,
            props: {
              label: 'label',
              value: 'value'
            },
            dicUrl: `${this.$baseUrl}general/get_product`,
            dicQuery: {},
            dicMethod: 'post',
            hide: true,
            showColumn: false
          },
          {
            label: '策划师',
            prop: 'teacher_id',
            type: 'select',
            search: true,
            props: {
              label: 'label',
              value: 'value'
            },
            dicUrl: `${this.$baseUrl}general/get_teacher`,
            dicQuery: {},
            dicMethod: 'post',
            hide: true,
            showColumn: false
          },
          {
            type: 'daterange',
            searchRange: true,
            label: '预约日期',
            span: 8,
            hide: true,
            display: false,
            search: true,
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            prop: 'datetime'
          },
          {
            label: '预约日期',
            prop: 'date',
            slot: true
          },
          {
            label: '能量值',
            prop: 'energy'
          },
          {
            label: '策划师',
            prop: 'teacher_name'
          },
          {
            label: '助理',
            prop: 'aid_name'
          },
          {
            label: '说明 ',
            prop: 'remark',
            type: 'textarea',
            addDisplay: true,
            editDisplay: true,
            span: 24
          },
          {
            label: '状态',
            prop: 'status',
            type: 'radio',
            dataType: 'string',
            search: 'true',
            value: 1,
            dicData: [
              {
                label: '待确认',
                value: 1
              },
              {
                label: '预约成功',
                value: 2
              },
              {
                label: '已完成',
                value: 3
              },
              {
                label: '已请假',
                value: 4
              },
              {
                label: '已作废',
                value: -1
              }
            ]
          },
          {
            label: '创建时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_time'
          },
          {
            label: '更新者',
            addDisplay: false,
            editDisplay: false,
            prop: 'update_user'
          },
          {
            label: '签到时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'sign_date'
          }
        ]
      },
      formPlanOp: {
        total: 20,
        align: 'center',
        cellBtn: false,
        cancelBtn: false,
        ...this.$store.getters.tableConfig,
        emptyText: '关闭',
        column: [
          {
            label: '会员名称',
            prop: 'member_name',
            type: 'input',
            search: true
          },
          {
            label: '预约课程',
            prop: 'course_name',
            type: 'input',
            search: true
          },
          {
            label: '预约日期',
            prop: 'date',
            slot: true
          },
          {
            label: '说明 ',
            prop: 'remark',
            type: 'textarea',
            addDisplay: true,
            editDisplay: true,
            span: 24
          },
          {
            label: '状态',
            prop: 'status',
            type: 'radio',
            dataType: 'string',
            search: 'true',
            value: 1,
            dicData: [
              {
                label: '待确认',
                value: 1
              },
              {
                label: '预约成功',
                value: 2
              },
              {
                label: '已完成',
                value: 3
              },
              {
                label: '已请假',
                value: 4
              },
              {
                label: '已作废',
                value: -1
              }
            ]
          }
        ]
      },
      searchData: {
        // datetime: []
      },
      formDa: {
        order_sn: ''
      },
      formGrowthDa: {
        type: 1,
        source: 1,
        number: 0,
        status: 1,
        is_show: 1
      },
      formGrowthOp: {
        column: [
          {
            label: '所属分类',
            prop: 'type',
            span: 24,
            formslot: true,
            display: true,
            value: 1,
            rules: [
              {
                required: true,
                message: '请选择所属分类',
                trigger: 'blur'
              }
            ]
          },
          {
            type: 'radio',
            label: '来源',
            value: 1,
            control: (val) => {
              if (val === 1) {
                return {
                  // 文章内容
                  content: {
                    display: true
                  },
                  // 外链地址
                  source_url: {
                    display: false
                  }
                };
              }
              if (val === 3) {
                return {
                  source_url: {
                    label: '视频feedId',
                    display: true,
                    tip: 'feedId唯一标识某一条视频，获取视频的feedId需要登录视频号助手，在「动态管理」模块可以复制自己发表的每个视频对应的feedId。'
                  },
                  content: {
                    display: false
                  }
                };
              }
              return {
                content: {
                  display: false
                },
                source_url: {
                  label: '外链地址',
                  display: true
                }
              };
            },
            prop: 'source',
            dicData: [
              {
                label: '本地',
                value: 1
              },
              {
                label: '公众号',
                value: 2
              },
              {
                label: '视频号',
                value: 3
              }
            ],
            span: 12,
            display: true,
            required: true,
            rules: [
              {
                required: true,
                message: '请选择来源'
              }
            ]
          },
          {
            type: 'number',
            label: '排序',
            span: 12,
            display: true,
            prop: 'sorting',
            value: 0
          },
          {
            label: '图集',
            prop: 'images',
            type: 'upload',
            limit: 10,
            span: 24,
            addDisplay: true,
            editDisplay: true,
            hide: true,
            propsHttp: {},
            dataType: 'string',
            listType: 'picture',
            accept: 'image/png, image/jpeg',
            oss: '',
            tip: '只能上传jpg/png文件，且不超过500kb',
            action: `${this.$baseUrl}system/upload`,
            required: true
            // rules: [
            //   {
            //     required: true,
            //     message: '图片必须上传'
            //   }
            // ]
          },
          {
            type: 'textarea',
            label: '外链地址',
            span: 24,
            prop: 'source_url',
            maxlength: 1000,
            minRows: 4,
            maxRows: 6,
            showWordLimit: true,
            display: false
          },
          {
            label: '活动点评',
            prop: 'review',
            type: 'dynamic',
            span: 24,
            children: {
              align: 'center',
              headerAlign: 'center',
              rowDel: (row, done) => {
                // this.$message.success(`删除回调${JSON.stringify(row)}`);
                console.log(this.formGrowthDa);
                done();
              },
              column: [
                {
                  type: 'select',
                  label: '上课老师',
                  dicUrl: `${this.$baseUrl}general/get_teacher`,
                  dicQuery: {},
                  dicMethod: 'post',
                  span: 12,
                  display: true,
                  props: {
                    label: 'label',
                    value: 'value'
                  },
                  prop: 'teacher_id',
                  required: true,
                  rules: [
                    {
                      required: true,
                      message: '上课老师必须填写'
                    }
                  ]
                },
                {
                  label: '点评',
                  prop: 'content',
                  type: 'textarea',
                  rules: [
                    {
                      required: true,
                      message: '老师点评必须填写'
                    }
                  ]
                }
              ]
            }
          },
          {
            type: 'ueditor',
            component: 'AvueUeditor',
            label: '精彩瞬间',
            span: 24,
            display: true,
            prop: 'content',
            maxlength: 500,
            minRows: 4,
            maxRows: 6,
            showWordLimit: true,
            action: `${this.$baseUrl}system/upload`
          },
          {
            type: 'radio',
            label: '状态',
            dicData: [
              {
                label: '禁用',
                value: -1
              },
              {
                label: '启用',
                value: 1
              }
            ],
            value: 1,
            span: 12,
            display: true,
            prop: 'status',
            rules: [
              {
                required: true,
                message: '请选择状态'
              }
            ],
            required: true
          },
          {
            // 显示状态
            label: '是否公开',
            type: 'radio',
            value: 1,
            dicData: [
              {
                label: '公开',
                value: 1
              },
              {
                label: '仅家长观看',
                value: 0
              }
            ],
            props: {
              label: 'label',
              value: 'value'
            },
            span: 12,
            display: true,
            prop: 'is_show'
          }
        ],
        labelPosition: 'right',
        labelSuffix: ':',
        labelWidth: 100,
        gutter: 0,
        menuBtn: true,
        submitBtn: true,
        submitText: '提交',
        emptyBtn: true,
        emptyText: '关闭',
        menuPosition: 'center'
      }
    };
  },
  computed: {},
  filters: {},
  methods: {
    // 分页
    onLoad(page) {
      this.page = page;
      this.getList();
    },
    // 筛选
    searchChange(params, done) {
      if (this.memberSearchFo.table) {
        params.member_id = this.memberSearchFo.table;
      }
      const { datetime } = params;
      if (datetime) {
        // eslint-disable-next-line prefer-destructuring
        params.start_time = datetime[0];
        // eslint-disable-next-line prefer-destructuring
        params.end_time = datetime[1];
      }
      delete params.datetime;

      this.searchData = params;
      this.page.currentPage = 1;
      this.getList();
      done();
    },
    // 筛选清空
    searchReset() {
      this.memberSearchFo.table = '';
      this.page.currentPage = 1;
      this.searchData = {
        datetime: []
      };
      this.getList();
    },
    searchExcel(params) {
      if (this.memberSearchFo.table) {
        params.member_id = this.memberSearchFo.table;
      }
      // 处理日期
      const { datetime } = params;
      if (datetime) {
        // eslint-disable-next-line prefer-destructuring
        params.start_time = datetime[0];
        // eslint-disable-next-line prefer-destructuring
        params.end_time = datetime[1];
      }
      delete params.datetime;
      // 导出
      dataApi.getExport.r(params).then((res) => {
        this.$confirm('是否下载导出数据?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          window.location.href = res.data;
        });
      });
    },
    // 添加
    handleSubmit(form, done, loading) {
      console.log('form', form, loading);
      dataApi.add.r(form).then((res) => {
        console.log(res);
        done(form);
        loading();
        this.getList();
      });
      done();
    },
    redact(form) {
      this.planDialogVisible = true;
      this.formPlanDa = form;
    },
    // 删除
    rowDel(form) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log('删除数据', form);
        dataApi.del.r({ id: form.id }).then((res) => {
          console.log(res);
          this.getList();
          this.$message.success('删除成功');
        });
      });
    },
    clear() {
      this.formGrowthDa = {
        type: 1,
        source: 1,
        number: 0,
        status: 1,
        is_show: 1
      };
    },
    // 成长记录
    growthClick(form) {
      console.log(form);
      this.formGrowthDa.member_id = form.member_id;
      this.formGrowthDa.order_id = form.id;
      this.formGrowthDa.baby_id = Number(form.baby_id);
      this.formGrowthDa.product_sku_id = form.product_sku_id;
      this.formGrowthDa.activity_id = form.product_id;
      this.formGrowthDa.sign_date = form.sign_date;
      this.growthVisible = true;
    },
    // 成长中心提交
    growthRowUpdate(form, done) {
      console.log(form);
      growApi.add.r(form).then(() => {
        this.growthVisible = false;
      });
      done();
      this.clear();
    },
    // 成长中心关闭
    growthEmptytChange() {
      this.growthVisible = false;
      this.clear();
    },
    // 关闭表单
    emptytChange() {
      this.formDa = {};
      this.planDialogVisible = false;
    },
    // 修改
    rowUpdate(form, index, done, loading) {
      console.log('index', form, index);
      dataApi.update.r(form).then((res) => {
        console.log(res);
        this.getList();
        done(form);
        loading();
      });
      done();
    },
    // 获取
    getList() {
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        ...this.searchData
      };
      dataApi.getList.r(postData).then((res) => {
        this.tableData = res.data.list;
        this.page.total = res.data.total;
      });
    }
  }
};
