import http from '@/utils/http';

/**
 * 成长中心
 */
export default {
  getList: {
    p: 'post,/manage/growth/getlist',
    r: (data) => http({ url: '/manage/growth/getlist', method: 'post', data })
  },
  add: {
    p: 'post,/manage/growth/add',
    r: (data) => http({ url: '/manage/growth/add', method: 'post', data })
  },
  update: {
    p: 'post,/manage/growth/update',
    r: (data) => http({ url: '/manage/growth/update', method: 'post', data })
  },
  del: {
    p: 'post,/manage/growth/delete',
    r: (data) => http({ url: '/manage/growth/delete', method: 'post', data })
  },
  getOne: {
    p: 'post,/manage/growth/getone',
    r: (data) => http({ url: '/manage/growth/getone', method: 'post', data })
  },
  growthreviewAdd: {
    p: 'post,/manage/growthreview/add',
    r: (data) => http({ url: '/manage/growthreview/add', method: 'post', data })
  },
  growthreviewDel: {
    p: 'post,/manage/growthreview/delete',
    r: (data) =>
      http({ url: '/manage/growthreview/delete', method: 'post', data })
  },
  growthreviewUpdate: {
    p: 'post,/manage/growthreview/update',
    r: (data) =>
      http({ url: '/manage/growthreview/update', method: 'post', data })
  },
  growthreviewGetlist: {
    p: 'post,/manage/growthreview/getlist',
    r: (data) =>
      http({ url: '/manage/growthreview/getlist', method: 'post', data })
  }
};
